@layer modules, ui, base;
@layer base {
  .Companies_main__X4NO3 {
  text-align: center;
}

.Companies_list__mHqg9 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: var(--sk-space-64);
  padding: 0;
  list-style: none;
  gap: var(--sk-space-20);
}

.Companies_logo__M8A19 {
  border-radius: var(--sk-radius-8);
}

@media (min-width: 768px) {
  .Companies_list__mHqg9 {
    grid-template-columns: repeat(4, 1fr);
    gap: var(--sk-space-40);
  }
}

@media (min-width: 1312px) {
  .Companies_list__mHqg9 {
    grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
    max-width: 64em; /* 1024px */
  }

  .Companies_item__IVyad {
    position: relative;
  }

  .Companies_item__withPermalink__lzoaB::before {
    content: "";
    display: none;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 100px;
    padding: 10px 0;
    border-radius: var(--sk-radius-8);
    /* stylelint-disable color-function-notation */
    box-shadow: 0 8px 16px hsla(240deg, 1%, 47%, 20%);
    cursor: pointer;
  }

  .Companies_item__IVyad:hover::before {
    display: block;
  }

  .Companies_logo__M8A19 {
    filter: grayscale(1);
    width: 80px;
    height: 80px;
  }

  .Companies_item__IVyad:hover .Companies_logo__M8A19 {
    filter: grayscale(0);
  }
}

}
@layer base {
  .Hero_wrapper__PMPii {
    background-color: var(--sk-color-black);
}

.Hero_heroPicture__RWdEn {
    display: none;
}

.Hero_title__wT8gq {
    margin-bottom: var(--sk-space-40);
    font-size: var(--sk-font-size-7) !important; /* 40px */
    line-height: var(--sk-line-heights-7) !important; /* 48px */
}

.Hero_preTitle__2jYtP {
    color: var(--sk-color-green-500);
}

.Hero_main__XnJgQ {
    --layout-size: 100%;
    position: relative;
    max-width: var(--layout-size);
    padding: var(--sk-space-80) var(--sk-space-16);
    color: var(--sk-color-white);
}


.Hero_description__nvR6N {
    font-size: 20px;
    line-height: 28px;
}

@media (min-width: 768px) {
    .Hero_main__XnJgQ {
        padding: 136px 104px var(--sk-space-64);
        overflow-x: clip;
    }

    .Hero_title__wT8gq {
        position: relative;
        z-index: var(--z-up-in-the-current-stacking-context);
        max-width: 520px;
        font-size: var(--sk-font-size-8) !important; /* 48px */
        line-height: var(--sk-line-heights-6) !important; /* 56px */
    }
    
    .Hero_description__nvR6N {
        max-width: 400px;
    }

    .Hero_heroPicture__RWdEn {
        display: block;
        position: absolute;
        right: -135px;
        bottom: -25px;
        width: 395px;
        height: 382px;
    }

    .Hero_heroPictureShorter__gW1rJ {
        right: 45px;
        bottom: -15px;
        width: 286px;
        height: 277px;
    }
}

@media (min-width: 1312px) {
    .Hero_main__XnJgQ {
        --layout-size: 77rem;
        width: 100%;
        max-width: var(--layout-size);
        margin: auto;
        padding: 136px 0 var(--sk-space-64);
    }

    .Hero_title__wT8gq {
        max-width: 650px;
        padding-left: var(--sk-space-48);
        font-size: 58px !important;
        line-height: var(--sk-line-heights-6) !important; /* 56px */
    }

    .Hero_heroPicture__RWdEn {
        position: absolute;
        top: 100px;
        right: 160px;
        width: 528px;
        height: 510px;
    }

    .Hero_heroPictureShorter__gW1rJ {
        right: 165px;
        bottom: -15px;
        width: 470px;
        height: 370px;
        -o-object-fit: cover;
           object-fit: cover;
        -o-object-position: left top;
           object-position: left top;
        border-radius: 14px;
    }

    .Hero_description__nvR6N {
        padding-left: var(--sk-space-48);
        font-size: 24px;
        line-height: 32px;
    }
}
}
